import session from '@/services/session';
import { useUserStore } from '@/stores/user';

import { pushToDataLayer } from '@/helpers/trackEvents';

const getUser = async () => {
  const userStore = useUserStore();
  if (!userStore.loaded) {
    userStore.loading = true;
    await userStore.getUser();
    userStore.loading = false;
    if (localStorage.getItem('promo-code')) localStorage.removeItem('promo-code');
  }
  return userStore.user;
};

// Is Loged in
export const isLoggedIn = (to, from, next) => {
  const { token, ...queryParams } = to.query;

  if (token) {
    const jwt = localStorage.getItem('lander-jwt');
    if (jwt) { session.removeToken(); }
    session.setToken(token);
    if (to.query.signupType) {
      pushToDataLayer(session.getUser(), to.query.signupType);
      // if (to.query.url) return next({ name: 'Welcome', query: { url: to.query.url } });
      return next({ name: 'Dashboard', query: queryParams });
    }
    if (to.query.redirect_route) {
      const route = JSON.parse(to.query.redirect_route);
      return next(route);
    }
  }
  if (session.isAuthenticated()) {
    return next({ name: 'Dashboard', query: queryParams });
  }
  return next();
};

// Not Loged In
export const notLoggedIn = async (to, from, next) => {
  if (!session.isAuthenticated()) {
    if ((to.name === 'WizardImport' && to.query.url) || (to.name === 'WizardUrl' && to.query.pageUrl)) {
      return next({ name: 'Register', query: to.query });
    }
    return next({ name: 'Login', query: to.query });
  }
  const user = await getUser();
  if (!user.settings.onboardingCompleted) return next({ name: 'Welcome', query: to.query });
  if (session.getUser() && session.getUser().isImpersonated) return next();
  if (!user.free_trial_acquired || (user.free_trial_acquired && user.promo_code)) return next({ name: 'ChoosePlan', query: to.query });
  if (!user.main_plan_subscription_id || !user.access) return next({ name: 'Account', params: { tab: !user.main_plan_subscription_id ? 'plans' : 'billing' }, query: to.query });
  if (to.name === 'Dashboard') {
    if (to.query.url) return next({ name: 'WizardImport', query: to.query });
    if (to.query.pageUrl) return next({ name: 'WizardUrl', query: to.query });
  }
  return next();
};

// OnBoarding Completed
export const onboardingCompleted = async (to, from, next) => {
  if (!session.isAuthenticated()) return next({ name: 'Login', query: to.query });
  const user = await getUser();
  if (user.settings.onboardingCompleted) return next({ name: 'ChoosePlan', query: to.query });
  if (user.free_trial_acquired && !user.promo_code) return next({ name: 'Dashboard', query: to.query });
  return next();
};

// Free Trial Acquired
export const freeTrialAcquired = async (to, from, next) => {
  if (!session.isAuthenticated()) return next({ name: 'Login', query: to.query });
  const user = await getUser();
  if (!user.settings.onboardingCompleted) return next({ name: 'Welcome', query: to.query });
  if (user.free_trial_acquired && !user.promo_code) return next({ name: 'Dashboard', query: to.query });
  return next();
};
